import React from 'react'
import BaseContainer from '../components/base-container';
import { Box, Typography } from '@material-ui/core';

const PrivacyPage: React.FC = () => {
  return <BaseContainer title='Privacy'>
      <Box mt={4} mb={4}>
        <Typography variant='h4'>
        NUS STUDENTS’ COMPUTING CLUB LEGAL INFORMATION & NOTICES
        </Typography>
        <Typography variant='body2'>
        Updated 24 October 2018
        </Typography>
      </Box>
      <Box mt={4} mb={4}>
        <Typography variant='h5'>1.	COPYRIGHT</Typography><br/>
        <Typography variant='body1'>
          A.	This Web-site and its contents are subject to copyright protection under the laws of Singapore 
            and, through international treaties, other countries. The copyright in the contents and materials
            available on this Web-site as a whole is owned by the NUS Students’ Computing Club. However, the 
            copyright in some contents and materials incorporated within this Web-site may be owned by third parties 
            where so indicated.
        </Typography>
        <br/>
        <Typography variant='body1'>
          B.	No part of the contents or materials available on this Web-site may be reproduced, licensed, sold, published, transmitted, modified, adapted, publicly displayed, broadcast (including storage in any medium by electronic means whether or not transiently for any purpose save as permitted herein) without the prior written permission of the NUS Students’ Computing Club. You may view this Web-site and its contents using your Web browser and save an electronic copy, or print out a copy, of parts of this Web site solely for your own information, research or study, provided you (a) do not modify the copy from how it appears in this Web-site; and (b) include the copyright notice "The NUS Students’ Computing Club, 2018" on such copy.
        </Typography>
        <br/>
        <Typography variant='body1'>
          C.	The NUS Students’ Computing Club's crests and logos should never be removed from pages on which they originally appear. The NUS Students’ Computing Club's webpages should always appear exactly as posted without variation, unless the prior written approval of the NUS Students’ Computing Club is obtained.
        </Typography>
        <br/>
        <Typography variant='body1'>
          D.	You must not otherwise exercise the copyright in the whole or any part of the contents and materials in this Web-site for any other purpose except as expressly permitted by any applicable law or with the NUS Students’ Computing Club's prior written consent.
        </Typography>
      </Box>
      <Box mt={4} mb={4}>
        <Typography variant='h5'>2.	TRADEMARKS</Typography><br/>
        <Typography variant='body1'>
          A.	The logo, crest and name of the NUS Students’ Computing Club or any of its affiliates are trademarks of the NUS Students’ Computing Club or its affiliates. The NUS Students’ Computing Club has policies governing the use of its name, including the names of its schools and programs, and its trademarks. The use, reproduction, copying or redistribution of trademarks without the prior written permission of the NUS Students’ Computing Club or its affiliates is prohibited. All other trademarks appearing on this Web-site are the marks of their respective owners.
        </Typography>
      </Box>
      <Box mt={4} mb={4}>
        <Typography variant='h5'>3.	LINKS FROM OTHER WEBSITES</Typography><br/>
        <Typography variant='body1'>
          A.	The NUS Students’ Computing Club supports and encourages good Netiquette. The NUS Students’ Computing Club should be informed of links from external websites. However, the NUS Students’ Computing Club reserves the right to require the removal of any links from external websites to the NUS STUDENTS’ COMPUTING CLUB website. Deep linking to NUS STUDENTS’ COMPUTING CLUB web pages is prohibited - any links created by the user to the NUS Students’ Computing Club's website should be text links containing our domain name and which transfer other visitors directly to our homepage. The NUS Students’ Computing Club requires that the contents of its website should not appear within the frames of others, nor be accompanied in any way by third-party material that may create a false or mistaken impression in the mind of the viewer about the NUS Students’ Computing Club's affiliation or association with or endorsement of the third-party site, frame, or material.
        </Typography>
      </Box>
      <Box mt={4} mb={4}>
        <Typography variant='h5'>4.	DISCLAIMER</Typography><br/>
        <Typography variant='body1'>
          A.	THE USER ACKNOWLEDGES AND AGREES THAT ALL THE INFORMATION ON THIS WEB-SITE IS PROVIDED "AS IS".
        </Typography>
        <br/>
        <Typography variant='body1'>
          B.	The NUS Students’ Computing Club ("the NUS Students’ Computing Club") has used reasonable endeavours to ensure that the information and materials posted on this Web-site are correct at the time of posting. However, the NUS Students’ Computing Club gives no warranty and accepts no responsibility or liability for the accuracy or the completeness of the information and materials provided here for any purpose whatsoever. No reliance should be made by any user on the information or material so posted; instead, the user should independently verify the accuracy and completeness of the information and/or materials with the originating or authorising faculty, department or other body.
        </Typography>
        <br/>
        <Typography variant='body1'>
          C.	The user acknowledges and agrees that the NUS Students’ Computing Club shall not be held responsible or liable in any way for any and/or all consequences (including, without limitation, damages for loss of profits, business interruption, or loss of information) that may be incurred by the user as a direct or indirect result of using, or the inability to use, any materials or contents on this Web-site, even if the NUS Students’ Computing Club has been advised of the possibility of such damages in advance; and no right of action will arise as a result of personal injury or property damage, howsoever arising, sustained as a result of reference to, or reliance upon, any information contained in, or omitted from, this Web-site, whether through neglect or otherwise.
        </Typography>
        <br/>
        <Typography variant='body1'>
          D.	The NUS Students’ Computing Club reserves the right at any time, from time to time, to make changes to the whole or any part of these terms and/or the services offered on this Web-site as it deems appropriate.
        </Typography>
        <br/>
        <Typography variant='body1'>
          E.	This Web-site may contain links to other World Wide Web sites or resources operated by parties other than the NUS Students’ Computing Club. Such links are provided as a service for the convenience of the users of this Web-site. As the NUS Students’ Computing Club has no control over such sites and resources, the user acknowledges and agrees that the NUS Students’ Computing Club is not responsible nor liable for any content or material on or available from such sites or resources. In providing such links, the NUS Students’ Computing Club does not in any way, expressly or implicitly, endorse the linked sites or resources or the respective contents thereof. The user further acknowledges and agrees that the NUS Students’ Computing Club shall not be responsible or liable, whether directly or indirectly, for any damage or loss caused or sustained by or alleged to be caused or sustained by the user, in connection with the use or reliance on any information or material available on such linked sites or resources.
        </Typography>
      </Box>
      <Box mt={4} mb={4}>
        <Typography variant='h5'>5.	PERSONAL DATA PROTECTION</Typography><br/>
        <Typography variant='body1'>
          A.	This is a NUS Students’ Computing Club (NUS STUDENTS’ COMPUTING CLUB) website. Thank you for examining our privacy statement. The NUS STUDENTS’ COMPUTING CLUB respects the privacy of individuals and recognizes the importance of the personal data you have entrusted to us and believe that it is our responsibility to properly manage, protect and process your personal data. As such we would like to inform you of how we process your personal data when you interact with us.
        </Typography>
        <br/>
        <Typography variant='body1'>
          B.	If you are only browsing this website or using the Search function, we do not capture data that allows us to identify you individually. This website automatically receives and records information on our server logs from your browser, including your IP address, cookie information, and the page(s) requested. Although user sessions are tracked, the users remain anonymous. Please note that this website may contain links to other websites not maintained by NUS STUDENTS’ COMPUTING CLUB. Such third-party websites are subject to their own data protection and privacy practices and you are encouraged to examine the privacy policies of those websites.
        </Typography>
        <br/>
        <Typography variant='body1'>
          C.	If you choose to share your personal data via an application or an e-mail or any other form, we recommend that you read our Data Protection Policy (Section 6.) explaining how we use your personal data when you interact with us via our website or in any other way.
        </Typography>
        <br/>
        <Typography variant='body1'>
          D.	If you have any queries on this policy or have any other queries in relation to how we process your personal data, please email us at privacy@nuscomputing.com.
        </Typography>
      </Box>
      <Box mt={4} mb={4}>
        <Typography variant='h5'>6.	DATA PROTECTION POLICY</Typography><br/>
        <Typography variant='body1'>
        A.	We, at the NUS Students’ Computing Club (“NUS STUDENTS’ COMPUTING CLUB”), take our responsibilities under the Personal Data Protection Act 2012 (the “PDPA”) seriously. We also recognize the importance of the personal data you have entrusted to us and believe that it is our responsibility to properly manage, protect and process your personal data. 
      </Typography><br/><Typography variant='body1'>
        B.	This Data Protection Policy is designed to assist you in understanding how we collect, use and/or disclose the personal data you have provided to us, as well as to assist you in making an informed decision before providing us with any of your personal data. 
      </Typography><br/><Typography variant='body1'>
        C.	If you, at any time, have any queries on this policy or any other queries in relation to how we may manage, protect and/or process your personal data, please do not hesitate to contact our Data Protection Officer (the “DPO”) at privacy@nuscomputing.com.
      </Typography><br/><Typography variant='body1'>
        D.	INTRODUCTION TO THE PDPA 
            i.	“Personal Data” is defined under the PDPA to mean data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organisation has or is likely to have access. Common examples of personal data could include names, identification numbers, contact information, medical records, photographs and video images. 
            ii.	We will collect your personal data in accordance with the PDPA. In general, before we collect any personal data from you, we will notify you of the purposes for which your personal data may be collected, used and/or disclosed, as well as obtain your consent for the collection, use and/or disclosure of your personal data for the intended purposes. 
      </Typography><br/><Typography variant='body1'>
        E.	PURPOSES FOR COLLECTION, USE & DISCLOSURE OF PERSONAL DATA 
      </Typography><br/><Typography variant='body1'>
      &emsp;  i.	Depending on your relationship with us (e.g. as an applicant, student, alumNUS Students’ Computing Club, staff, donor, vendor, service provider, or other person relating to our organisation), the personal data which we collect from you may be collected, used and/or disclosed for the following purposes: 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;1.	(a) Evaluating suitability for admission or employment, enrolling or employing, providing educational courses and training, including sending materials on course / study / assignment / lecture materials, information on time tables and examination details via postal mail, electronic mail, SMS or MMS, fax and/or voice calls; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;2.	(b)  Administering and/or managing relationships with NUS STUDENTS’ COMPUTING CLUB (including responding to enquiries, the mailing of correspondence, statements or notices which could involve the disclosure of certain personal data to bring about delivery of the same); 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;3.	(c)  Carrying out due diligence or other screening activities (including background checks) in accordance with legal or regulatory obligations or risk management procedures that may be required by law or put in place by NUS STUDENTS’ COMPUTING CLUB, including the obtaining of references and/or other information from prior educational institutions and employers; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;4.	(d)  Processing application(s) for scholarships and/or financial aid, and research support, and administering and managing scholarship/financial aid/grant and other support programmes, which may include use of personal data for development and fund raising activities and disclosure of personal data to donors, grantors, external evaluators and/or external organisations for purposes of periodic reports, event invitations, surveys and/or publicity of NUS STUDENTS’ COMPUTING CLUB’ related programmes; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;5.	(e)  Investigating possible fraud, misconduct, unlawful action or omission, and utilizing electronic access and video systems to maintain campus security of persons or property, control access and investigate suspicious or inappropriate activities; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;6.	(f)  Responding to requests for information from government or public agencies, ministries, statutory boards or other similar authorities or non-government agencies authorised to carry out specific Government services or duties; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;7.	(g)  Carrying out market-related, evaluative or similar research and analysis for NUS STUDENTS’ COMPUTING CLUB’ operational strategy and policy planning purposes, including providing data to external parties for university programme evaluation and to students’ former academic institutions and to partner institutions for jointly- administered programmes; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;8.	(h)  Outreach and engagement to garner support and resources for NUS STUDENTS’ COMPUTING CLUB, its community and affiliated institutions; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;9.	(i)  Supporting NUS STUDENTS’ COMPUTING CLUB functions including, but not restricted to, the teaching and personal and professional development of students, research and administration of NUS STUDENTS’ COMPUTING CLUB; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;10.	(j)  Processing and administering applications for overseas exchange programmes, summer school, overseas internships and other overseas activities and administering such programmes including disclosure of information to overseas universities, employing and training organisations; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;11.	(k)  Processing, administering and conferring awards of prizes, medals, scholarships, classes of honours and other marks of distinction, and student or graduation status, and publication or releasing of information on the same; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;12.	(l)  Engaging alumni including but not limited to notification on NUS STUDENTS’ COMPUTING CLUB and alumni-related initiatives and activities, invitation to NUS STUDENTS’ COMPUTING CLUB and alumni-related events, updating of alumni information, invitation to participate in alumni surveys and sending of communication collaterals; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;13.	(m)  Processing applications and enrolment activities related to health, life and travel insurance and service provision as well as university-related concession cards, and campus accommodation and administering matters related to use of these services; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;14.	(n)  Processing applications for and administering local and overseas career related activities, events, programmes, internships, employment opportunities, and career coaching, and sharing information with companies (whether local or overseas) for purposes of recruitment, internship, industrial attachment, job placement and research support; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;15.	(o)  Facilitating participation in student life and alumni development opportunities which may include social, cultural, athletic, and educational activities, events, volunteering and training programmes, student membership and leadership positions in clubs, societies, halls and residences, and orientation/reception activities; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;16.	(p)  Taking of photographs and/or videos (whether by NUS STUDENTS’ COMPUTING CLUB staff or third-party photographers and/or videographers) during events or seminars organised by NUS STUDENTS’ COMPUTING CLUB or its affiliates for publicity purposes; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;17.	(q)  If consented to in the registration form and/or other methods of consent notification, providing marketing, advertising and promotional information via postal mail, electronic mail, SMS or MMS, fax and/or voice calls; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;18.	(r)  Any other purposes which NUS STUDENTS’ COMPUTING CLUB may inform you of in writing from time to time, but for which NUS STUDENTS’ COMPUTING CLUB will seek your separate consent. 
      </Typography><br/><Typography variant='body1'>
      F.	In order to conduct our operations more smoothly, we may also be disclosing the personal data you have provided to us to our third-party service providers, agents and/or our affiliates or related corporations, which may be sited locally or outside of Singapore, for one or more of the above-stated Purposes. This is because such third-party service providers, agents and/or affiliates or related corporations would be processing your personal data on our behalf for one or more of the above-stated Purposes.
      </Typography><br/><Typography variant='body1'>
      G.	SPECIFIC ISSUES FOR THE DISCLOSURE OF PERSONAL DATA TO THIRD PARTIES 
      </Typography><br/><Typography variant='body1'>
      &emsp;i.	We respect the confidentiality of the personal data you have provided to us. 
      </Typography><br/><Typography variant='body1'>
      &emsp;ii.	In that regard, we will not disclose any of your personal data to any third parties without first obtaining your express consent permitting us to do so. However, please note that we may disclose your personal data to third parties without first obtaining your consent in certain situations, including, without limitation, the following: 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;1.	(a)  the disclosure is required based on the applicable laws and/or regulations; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;2.	(b)  the purpose of such disclosure is clearly in your interests and consent cannot be obtained in a timely way; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;3.	(c)  the disclosure is necessary to respond to an emergency that threatens the life, health or safety of yourself or another individual; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;4.	(d)  there are reasonable grounds to believe that the health or safety of yourself or another individual will be seriously affected and consent for the disclosure of the data cannot be obtained in a timely way, provided that we shall, as soon as may be practicable, notify you of the disclosure and the purposes of the disclosure; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;5.	(e)  the disclosure is necessary for any investigation or proceedings; 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;6.	(f)  the personal data is disclosed to any officer of a prescribed law enforcement agency, upon production of written authorisation signed by the head or director of that law enforcement agency or a person of a similar rank, certifying that the personal data is necessary for the purposes of the functions or duties of the officer; and/or 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;7.	(g)  the disclosure is to a public agency and such disclosure is necessary in the public interest. 
      </Typography><br/><Typography variant='body1'>
        H.	The instances listed above at paragraph G.ii are not intended to be exhaustive. For an exhaustive list of exceptions, you are encouraged to peruse the PDPA which is publicly available at http://statutes.agc.gov.sg. 
      </Typography><br/><Typography variant='body1'>
        I.	In all other instances of disclosure of personal data to third parties with your express consent, we will provide for adequate forms of protection over such personal data and confidentiality and security in the handling and administration of your personal data by such third parties in compliance with the PDPA and our data protection policies. 
      </Typography><br/><Typography variant='body1'>
        J.	REQUEST FOR ACCESS, CORRECTION AND/OR WITHDRAWAL OF PERSONAL DATA 
      </Typography><br/><Typography variant='body1'>
      &emsp;    i.	You may request to access and/or correct the personal data currently in our possession or withdraw your consent for the collection, use and/or disclosure of your personal data in our possession or under our control at any time by submitting your request through the following methods: 
      </Typography><br/><Typography variant='body1'>
      &emsp;&emsp;1.	(a)  Email: privacy@nuscomputing.com
      </Typography><br/><Typography variant='body1'>
      &emsp;ii.	For a request to access personal, we will provide you with the relevant personal data within a reasonable time from such a request being made. 
      </Typography><br/><Typography variant='body1'>
      &emsp;iii.	For a request to correct personal data, we will process your request, including undertaking necessary verification activities, as soon as practicable after the request has been made. We will send the corrected personal data to every other organisation to which the personal data was disclosed by NUS STUDENTS’ COMPUTING CLUB within a year before the date the correction was made, unless that other organisation does not need the corrected personal data for any legal or business purpose, or if you so consent, only to specific organisations to which the personal data was disclosed by us within a year before the date the correction was made. We may also be charging you a reasonable fee for the handling and processing of your requests to access and/or correct your personal data, but you will be notified in advance of such costs. 
      </Typography><br/><Typography variant='body1'>
      &emsp;iv.	For a request to withdraw consent, we will process your request within a reasonable time from such a request for withdrawal of consent being made. Requests for withdrawal of consent may adversely impact your relationship with NUS STUDENTS’ COMPUTING CLUB. We will notify you in advance of such impacts. 
      </Typography><br/><Typography variant='body1'> 
        K.	ADMINISTRATION AND MANAGEMENT OF PERSONAL DATA 
      </Typography><br/><Typography variant='body1'>
      &emsp;i.	We will take appropriate measures to keep your personal data accurate, complete and updated. 
      </Typography><br/><Typography variant='body1'>
      &emsp;ii.	We will also take reasonable efforts to take appropriate precautions and preventive measures to ensure that your personal data is adequately protected and secured. Appropriate security arrangements will be taken to prevent any unauthorized access, collection, use, disclosure, copying, modification, leakage, loss, damage and/or alteration of your personal data. However, we cannot assume responsibility for any unauthorized use of your personal data by third parties which are wholly attributable to factors beyond our control. 
      </Typography><br/><Typography variant='body1'>
      &emsp;iii.	We will also take reasonable efforts to ensure that the personal data in our possession or under our control is destroyed and/or anonymized as soon as it is reasonable to assume that (i) the purpose for which that personal data was collected is no longer being served by the retention of such personal data; and (ii) retention is no longer necessary for any other legal or business purposes. 
      </Typography><br/><Typography variant='body1'>
      &emsp;iv.	This Data Protection Policy applies only to NUS STUDENTS’ COMPUTING CLUB websites. Our websites may contain links to other websites not maintained by NUS STUDENTS’ COMPUTING CLUB. Such third-party websites are subject to their own data protection and privacy practices and you are encouraged to examine the privacy policies of those websites. 
      </Typography><br/><Typography variant='body1'>
        L.	UPDATES ON DATA PROTECTION POLICY 
      </Typography><br/><Typography variant='body1'>
      &emsp;i.	As part of our efforts to ensure that we properly manage, protect and process your personal data, we will be reviewing our policies, procedures and processes from time to time. 
      </Typography><br/><Typography variant='body1'>
      &emsp;ii.	We may amend the terms of this Data Protection Policy at our absolute discretion. Any amended Data Protection Policy will be posted on our website and can be viewed at https://nuscomputing.com/privacy
      </Typography><br/><Typography variant='body1'>
      &emsp;iii.	You are encouraged to check the NUS Students’ Computing Club Data Protection Policy from time to time to ensure that you are well informed of our latest policies in relation to personal data protection. 
      </Typography>
      </Box>
      <Box mt={4} mb={4}>
        <Typography variant='h5'>7.	NON-DISCRIMINATION</Typography><br/>
        <Typography variant='body1'>
          A.	It is the policy of the NUS Students’ Computing Club to comply with all Singapore non-discrimination laws and the relevant Singapore government policies. This non-discrimination policy applies to admissions, employment, access to and treatment in the NUS Students’ Computing Club programs and activities. Complaints of invidious discrimination prohibited by NUS Students’ Computing Club policy are to be resolved within existing NUS Students’ Computing Club procedures.
        </Typography>
      </Box>
  </BaseContainer>;
}

export default PrivacyPage
